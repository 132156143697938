import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseCrudUpdComponent } from '../../../../common/bases/base.crud.upd.component';

import { AuthenticationService } from '../../../../services/authentication.service';
import { BuildingService } from '../../../../services/building.service';
import { FloorService } from '../../../../services/floor.service';

import { logicutils } from '../../../../common/logicutils';
import { navutils } from '../../../../common/navutils';


@Component({
  selector: 'app-floor-upd',
  templateUrl: './floor-upd.component.html'
})
export class FloorUpdComponent extends BaseCrudUpdComponent {

  public static openDialog(dialog: MatDialog, floor_id: string, closed_callback) {
    navutils.openModalDialog(dialog, FloorUpdComponent, "60%", "650px", { floor_id: floor_id }, closed_callback);
  }

  public buildings: any;
  public image: string;
  protected image_fallback = "#";

  /**
   * Initializes a new instance of FloorUpdComponent
   */
  constructor(protected formBuilder: FormBuilder, protected router: Router,
    protected route: ActivatedRoute,
    protected authenticationService: AuthenticationService,
    private floorService: FloorService,
    private buildingService: BuildingService,
    public dialogRef: MatDialogRef<FloorUpdComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { floor_id: any; popup_mode: boolean }
  ) {
    super(router, route, formBuilder, authenticationService)
  }

  protected getRecordId(): string {
    return this.data.floor_id;
  }


  protected onGetTitle(): string {
    return "$Floor$";
  }

  /**
   * Initialize the form model
   * */
  protected initializeForm(): void {
    this.editForm = this.formBuilder.group({
      _id: [],
      building_data: [null, Validators.required],
      name: ['', Validators.required],
      level: [0, Validators.required],
      N: [2.5, Validators.required],
      ptx: [-55, Validators.required],
      deviceZ: [1, Validators.required],
      dimension: this.formBuilder.group({
        width: ['', Validators.required],
        height: ['', Validators.required],
        ceiling_height: ['', Validators.required]
      }),
    });
  }

  /**
   * Gets the flr for the required id
   * */
  protected fillData(record_id: string): void {

    // fills the buildings for the lookup
    this.buildingService.getBuildings().subscribe(data => {
      this.buildings = data;
    });

    if (!this.is_create)
      this.floorService.getFloor(record_id).subscribe(data => {
        this.setFormValues(data);
      });
  }

  /**
   * Fills the data of the flr on the form
   * @param floor_record
   */
  setFormValues(floor_record: any) {
    this.setImage(floor_record.image);
    this.editForm.patchValue({
      _id: floor_record._id,
      building_data: floor_record.building_data,
      name: floor_record.name,
      level: floor_record.level,
      N: floor_record.N,
      ptx: floor_record.ptx,
      deviceZ: floor_record.deviceZ,
      dimension: {
        width: floor_record.dimension.width,
        height: floor_record.dimension.height,
        ceiling_height: floor_record.dimension.ceiling_height
      },
    });
  }

  /**
   * Sets the imge of the record
   * @param image
   */
  private setImage(image: string) {
    this.image = image;
  }

  /**
   * Uploads the image to the current record
   * @param event
   */
  async uploadImage(event) {
    logicutils.uploadImageAndCheckSize(event, this);
  }

  /**
   * Submit the form and saves the flr in the database
   * */
  public async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid)
      return;


    let building_id = this.form.building_data.value;
    let floor_name = this.form.name.value;
    let floor_level = this.form.level.value;
    let floor_id = this.form._id.value;

    let name_exists = await this.floorService.checkNameExistsForBuilding(floor_name, building_id, floor_id).toPromise();
    if(name_exists)
      return this.setError('name', 'used');

    let level_exists = await this.floorService.checkLevelExistsForBuilding(floor_level, building_id, floor_id).toPromise();
    if(level_exists)
      return this.setError('level', 'used');
    
    let json = this.editForm.value;
    json["image"] = this.image;
    if (this.is_create)
      this.floorService.addFloor(json, this.returnList());
    else
      this.floorService.updFloor(json, this.returnList());
    this.close(true);
  }

  public returnList() {
    this.router.navigate(['forms/floors/list']);
  }

  public createRooms() {
    this.close(false);
    this.router.navigate(['wizards/add-rooms', this.record_id]);
  }

  public createDepartments() {
    this.close(false);
    this.router.navigate(['wizards/add-departments', this.record_id]);
  }

  public createExclusions() {
    this.close(false);
    this.router.navigate(['wizards/add-exclusions', this.record_id]);
  }

  public createGateways() {
    this.close(false);
    this.router.navigate(['wizards/add-gateways', this.record_id]);
  }

  public createSensors() {
    this.close(false);
    this.router.navigate(['wizards/add-sensors', this.record_id]);
  }

  public locationPicker() {
    this.close(false);
    this.router.navigate(['wizards/location-picker', this.record_id]);
  }


  public close(response: boolean) {
    this.dialogRef.close(response);
  }
}