import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { HighchartsChartModule } from 'highcharts-angular';

import { NgModule } from '@angular/core';
import { EditorsModule } from "../../common/editors.module";
import { ComponentsModule } from '../../common/components.module';

import { DashboardRoutingModule } from './dashboard-routing.module';

// main components
import { MapComponent } from './map/map.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardReportComponent } from './dashboard/dashboard-report.component';
import { DashboardMaintenanceComponent } from './dashboard-maintenance/dashboard-maintenance.component';
import { DashboardBatteryComponent } from './dashboard-battery/dashboard-battery.component';
import { DashboardConsumerComponent } from './dashboard-consumer/dashboard-consumer.component';

// dashboard widgets

import { DashboardAvailabilityStatusComponent } from './dashboard/Components/dashboard-availability-status.component';
import { DashboardAvailabilityComponent } from './dashboard/Components/dashboard-availability.component';
import { DashboardCountersComponent } from './dashboard/Components/dashboard-counters.component';
import { DashboardCountersBatteryComponent } from './dashboard-battery/Components/dashboard-counters-battery.component';
import { DashboardCountersBatteryMonthsComponent} from './dashboard-battery/Components/dashboard-counters-battery-months.component';
import { DashboardDistributionKindComponent } from './dashboard/Components/dashboard-distribution-kind.component';
import { DashboardDistributionStatusComponent } from './dashboard/Components/dashboard-distribution-status.component';
import { DashboardDistributionDepartmentComponent } from './dashboard/Components/dashboard-distribution-department.component';
import { DashboardUsageRateComponent } from './dashboard/Components/dashboard-usage-rate.component';
import { DashboardSleepingEquipmentsComponent } from './dashboard/Components/dashboard-sleeping-equipments.component';

import { DashboardInputOutputMonitoringComponent } from './dashboard/Components/dashboard-input-output-monitoring.component';
import { DashboardParkAgeComponent } from './dashboard/Components/dashboard-park-age.component';

// dashboard maintenance widgets
import { DashboardMaintenanceCountersComponent } from './dashboard-maintenance/Components/dashboard-maintenance-counters.component';
import { DashboardEquipmentLevelsComponent } from './dashboard-maintenance/Components/dashboard-maintenance-equipment-levels.component';
import { DashboardMaintenanceHistoryComponent } from './dashboard-maintenance/Components/dashboard-maintenance-history.component';
import { DashboardMaintenancePrevisionalComponent } from './dashboard-maintenance/Components/dashboard-maintenance-previsional.component';
import { DashboardMaintenanceLevelRateComponent } from './dashboard-maintenance/Components/dashboard-maintenance-level-rate.component';
import { DashboardMaintenanceDistributionKindComponent } from './dashboard-maintenance/Components/dashboard-maintenance-distribution-kind.component';
import { DashboardMaintenanceDistributionFloorComponent } from './dashboard-maintenance/Components/dashboard-maintenance-distribution-floor.component';
import { DashboardConsumerCountersComponent } from './dashboard-consumer/Components/dashboard-consumer-counters.component';


@NgModule({
  imports: [
    DashboardRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    HighchartsChartModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,

    EditorsModule,
    ComponentsModule,
  ],
  declarations: [
    // main components
    MapComponent,
    DashboardComponent,
    DashboardReportComponent,
    DashboardMaintenanceComponent,
    DashboardBatteryComponent,

    // dashboard widgets
    DashboardAvailabilityStatusComponent,
    DashboardAvailabilityComponent,
    DashboardCountersComponent,
    DashboardCountersBatteryComponent,
    DashboardCountersBatteryMonthsComponent,
    DashboardDistributionKindComponent,
    DashboardDistributionStatusComponent,
    DashboardDistributionDepartmentComponent,
    DashboardUsageRateComponent,
    DashboardSleepingEquipmentsComponent,
    DashboardInputOutputMonitoringComponent,
    DashboardParkAgeComponent,

    // dashboard maintenance widgets
    DashboardMaintenanceCountersComponent,
    DashboardEquipmentLevelsComponent,
    DashboardMaintenanceHistoryComponent,
    DashboardMaintenancePrevisionalComponent,
    DashboardMaintenanceLevelRateComponent,
    DashboardMaintenanceDistributionKindComponent,
    DashboardMaintenanceDistributionFloorComponent,

    DashboardConsumerComponent,
    DashboardConsumerCountersComponent
  ],
  exports: [
    DashboardUsageRateComponent,
  ],
  providers: [
  ]
})
export class DashboardModule { }
