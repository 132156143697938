
<div class="dialog-header">
  <app-page-title title="{{title}}" mode="popup_mode"></app-page-title>
</div>

<div class="dialog-body">

  <form [formGroup]="editForm">

    <input type="hidden" formControlName="_id" name="_id" id="_id">

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-2"><label>{{ 'Building' | translate }} :</label></div>
        <div class="col-md-4">
          <app-form-select [field]="form.building_data" [list]="buildings" id="_id" text="name" [submitted]="submitted"></app-form-select>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-2"><label>{{ 'Name' | translate }} :</label></div>
        <div class="col-md-4">
          <app-form-input [field]="form.name" [submitted]="submitted"></app-form-input>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-2"><label>{{ 'Level' | translate }} :</label></div>
        <div class="col-md-4">
          <app-form-input type="number" [field]="form.level" [submitted]="submitted"></app-form-input>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-2"><label>{{ 'N' | translate }} :</label></div>
        <div class="col-md-4">
          <app-form-input type="number" [field]="form.N" [submitted]="submitted"></app-form-input>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-2"><label>{{ 'PTX' | translate }} :</label></div>
        <div class="col-md-4">
          <app-form-input type="number" [field]="form.ptx" [submitted]="submitted"></app-form-input>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row row-center">
        <div class="col-md-2"><label>{{ 'Device Z' | translate }} (m) :</label></div>
        <div class="col-md-4">
          <app-form-input type="number" [field]="form.deviceZ" [submitted]="submitted"></app-form-input>
        </div>
      </div>
    </div>

    <div formGroupName="dimension">
      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-2"><label>{{ 'Width' | translate }} (m) :</label></div>
          <div class="col-md-4">
            <app-form-input type="number" [field]="editForm.get('dimension.width')" [submitted]="submitted"></app-form-input>
            <!-- <input type="number" formControlName="width" name="width" class="form-control" id="width" [ngClass]="{ 'is-invalid': submitted && form.width.errors }">
            <app-form-error [field]="form.width" [submitted]="submitted"></app-form-error> -->
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-2"><label>{{ 'Length' | translate }} (m) :</label></div>
          <div class="col-md-4">
            <app-form-input type="number" [field]="editForm.get('dimension.height')" [submitted]="submitted"></app-form-input>
            <!-- <input type="number" formControlName="height" name="height" class="form-control" id="height" [ngClass]="{ 'is-invalid': submitted && form.height.errors }">
            <app-form-error [field]="form.height" [submitted]="submitted"></app-form-error> -->
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row row-center">
          <div class="col-md-2"><label>{{ 'Ceiling height' | translate }} (m) :</label></div>
          <div class="col-md-4">
            <app-form-input type="number" [field]="editForm.get('dimension.ceiling_height')" [submitted]="submitted"></app-form-input>
            <!-- <input type="number" formControlName="ceiling_height" name="ceiling_height" id="ceiling_height" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.ceiling_height.errors }">
            <app-form-error [field]="form.ceiling_height" [submitted]="submitted"></app-form-error> -->
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!is_create" class="form-group">
      <div class="row row-center">
        <div class="col-md-2">
          <label for="image">{{ 'Image' | translate }} :</label>
          <button type="button" class="btn btn-small" (click)="fileSelect.click()"><i class="fa fa-plus" aria-hidden="true"></i></button>
          <button type="button" class="btn btn-small" (click)="setImage(null)"><i class="fa fa-trash" aria-hidden="true"></i></button>
        </div>
        <div></div>
        <div class="col-md-4">
          <img [src]="image || image_fallback | safe: 'img'" style="max-width:200px;">
          <input type="file" #fileSelect class="form-control browse" (change)="uploadImage($event)" style="display:none">
        </div>
      </div>
    </div>

  </form>

</div>

<div class="dialog-footer">
  <button class="btn-rounded btn-add" (click)="onSubmit()">{{ 'Save' | locale }}</button>
  <button class="btn-rounded btn-close" (click)="close(false)">{{ 'Close' | locale }}</button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="createRooms()" text="Rooms" icon="pen"></app-form-button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="createDepartments()" text="Departments" icon="pen"></app-form-button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="createExclusions()" text="Exclusions" icon="pen"></app-form-button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="createGateways()" text="Gateways" icon="pen"></app-form-button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="createSensors()" text="Sensors" icon="pen"></app-form-button>
  <app-form-button *ngIf="!is_create && rights['right_supervisor']" (click)="locationPicker()" text="Picker" icon="pen"></app-form-button>
</div>