
<table class="table compact">
  <thead>
    <tr>
      <th *ngFor="let header of event_gateways_header; let j=index" style="width: 80px; text-align: center;">{{header}}</th>
      <th style="flex:1;"></th>
    </tr>
    <tr>
      <th *ngFor="let header of event_gateways_header_level; let j=index" [ngStyle]="{'background-color': header == current_event.floor_level ? '#FFC' : 'white' }" style="font-weight:bold; width: 80px; text-align: center;">{{header}}</th>
      <th style="flex:1;"></th>
    </tr>
  </thead>
  <tr *ngFor="let location of event_gateways; let i=index">
    <td *ngFor="let evt of location; let j=index" style="font-size: 10px; font-family: monospace"
        [ngStyle]=" {'background-color': (i==0 && j<2) ? '#FFC': j>1 ? getRssiColor(evt) : null, 'text-align': j>1 ? 'center' : 'right', 'color': j>1 ? 'white' : '#2D6C9B'}">
      {{evt=="-105.01" ? "" : evt}}
    </td>
  </tr>
</table>


<app-panel *ngIf="is_admin && current_event" title="Received gateways" [opened]="false">

  <table *ngIf="current_event.gateways" class="table compact scrollable debug" style="margin:0px; width:580px;">
    <thead>
      <tr (click)="sortTable($event)">
        <th style="width:140px">Gateway</th>
        <th style="width:140px">Name</th>
        <th style="width:40px">Level</th>
        <th style="width:40px">N</th>
        <!--<th style="width:40px">N M</th>
        <th style="width:40px">N M E</th>-->
        <th style="width:40px">RSSI</th>
        <th style="width:40px">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let gtw of current_event.all_gateways" [ngStyle]="{'background-color': gtw.match ? '#FFC' : 'white' }">
        <td style="width:140px;">{{ gtw.cloud_id }}</td>
        <td style="width:140px;">{{ gtw.name }}</td>
        <td style="width:40px;">{{ gtw.level }}</td>
        <td style="width:40px;">{{ gtw.n_rssi }} </td>
        <!--<td style="width:40px;">{{ gtw.n_multi_floor }} </td>
        <td style="width:40px;">{{ gtw.n_multi_floor_extender }} </td>-->
        <td style="width:40px;">{{ gtw.rssi }}</td>
        <td style="width:40px; background-color:lightsteelblue; text-align:center; color:white;" (click)="onNavigateGateway(gtw.cloud_id)">></td>
      </tr>
    </tbody>
  </table>

</app-panel>