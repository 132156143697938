import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { HighchartsChartModule } from 'highcharts-angular';

import { TranslateModule } from '@ngx-translate/core';
import { EditorsModule } from './editors.module';

import { FloorPlanComponent } from './work-components/floor-plan.component';
import { HistoricChartComponent } from './work-components/historic-chart.component';
import { InfoRibbonComponent } from './work-components/info-ribbon.component';
import { FilterRibbonComponent } from './work-components/filter-ribbon.component';
import { HistoryLocationComponent } from './work-components/history-location.component';
import { HistoryStatusComponent } from './work-components/history-status.component';
import { StatusDistributionComponent } from './work-components/status-distribution.component';
import { DebugRibbonComponent } from './work-components/debug-ribbon.component';
import { LightRibbonComponent } from './work-components/light-ribbon.component';
import { ItemsListComponent } from './work-components/items-list.component';
import { EventsListRibbonComponent } from './work-components/events-list-ribbon.component';
import { EventsListListComponent } from './work-components/events-list-list.component';
import { PositionPickerComponent } from './work-components/position-picker.component';
import { PositionPickerDialogComponent } from './work-components/position-picker/position-picker-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    HighchartsChartModule,
    EditorsModule,
    RouterModule,
    NgbModule, //.forRoot(),

    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
  ],
  declarations: [
    FloorPlanComponent,

    HistoricChartComponent,
    InfoRibbonComponent,
    FilterRibbonComponent,
    LightRibbonComponent,
    HistoryLocationComponent,
    HistoryStatusComponent,
    StatusDistributionComponent,
    DebugRibbonComponent,
    ItemsListComponent,
    EventsListRibbonComponent,
    EventsListListComponent,
    PositionPickerComponent,
    PositionPickerDialogComponent,
  ],
  exports: [
    TranslateModule,

    FloorPlanComponent,
    HistoricChartComponent,
    InfoRibbonComponent,
    FilterRibbonComponent,
    LightRibbonComponent,
    HistoryLocationComponent,
    HistoryStatusComponent,
    StatusDistributionComponent,
    DebugRibbonComponent,
    ItemsListComponent,
    EventsListRibbonComponent,
    EventsListListComponent,
    PositionPickerComponent,
  ]
})
export class ComponentsModule { }
